.cursor {
  width: 30px;
  height: 30px;
  background-image: url("/public/assets/icons/삼족오30.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.cursor--clicked {
  background-image: url("/public/assets/icons/삼족오30-rotate.png");
}

.cursor--link-hovered {
  /* width: 35px;
  height: 35px; */
  /* background-image: url("/public/assets/icons/삼족오35.png"); */
}

.cursor-border {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #7f2ca7;
  position: fixed;
  pointer-events: none;
  z-index: 9998;
  opacity: 0;
  transition: opacity 0.2s ease;
  transform: translate(-50%, -50%);
}

.cursor-border--link-hovered {
  opacity: 1;
}

.swiper-button-next,
.swiper-button-prev {
  cursor: none !important;
}
