:root {
  --header-height: 80px;
  --calc-vh: calc(100vh - var(--header-height));
  --blue-side: #00AEEF,
  --navy-side: #316ABA,
  --purple-side: #632585,
  --base-font-size: 1vw;
  color-scheme: light;
}

::webkit-scrollbar{
  display: none !important;
}

html {
  font-size: 62.5%;
  /* font-size: .8vw; 1rem = 8px로 설정 */
  /* font-size: calc(var(--base-font-size) * 6.25); */
  /* font-size: .8vw; */
  @media (max-width: 1920px) {
    font-size: calc(64.5% * 0.8);
  }
}

body {
  margin: 0;
  padding: 0;
  /* cursor: url(/public/assets/icons/삼족오30.png), auto; */
  background-color: white;
  color: black;
}

body:active {
  /* cursor: url(/public/assets/icons/삼족오30-rotate.png), auto; */
}

.custom-cursor {

}


* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
a {
  /* cursor: pointer; */
  /* cursor: url(/public/assets/icons/삼족오30.png), auto; */
}

a:hover {
  /* cursor: url(/public/assets/icons/삼족오35.png), auto; */
}

button {
  border: none;
  background-color: #ffffff00;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}



@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}

/* @media (min-width: 768px) and (max-width: 1400px) {
  html {
    font-size: 80%;
  }
} */

/* 최소 및 최대 폰트 크기 설정 */
/* @media screen and (max-width: 1000px) {
  :root {
    --base-font-size: 10px;
  }
}

@media screen and (min-width: 2000px) {
  :root {
    --base-font-size: 20px;
  }
} */