.swiper {
  max-width: 100%;
  overflow-x: hidden;
}

.swiper-pagination-bullet {
  width: 18px !important;
  height: 2px !important;
  background: #ccd1d7 !important;
  opacity: 1 !important;
  margin: 0 5px !important;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  background: #00aeef !important;
}

.keymember .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.popup .swiper-pagination {
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.popup .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background: #6d6d6d !important;
  opacity: 1 !important;
  margin: 0 !important;
}

.popup .swiper-pagination-bullet-active {
  background: #000 !important;
}

.home-news,
.product-swiper {
  max-width: 1160px;
  /* overflow: hidden; */
  background-color: #fff;
  position: static;
}

.home-news .swiper-slide,
.product-swiper .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-news .swiper-button-next {
  background: url("/public/assets/icons/slide_arrow_right.png") no-repeat;
  width: 58px;
  height: 58px;
  top: 135px;
}

.home-news .swiper-button-prev {
  background: url("/public/assets/icons/slide_arrow_left.png") no-repeat;
  width: 58px;
  height: 58px;
  top: 135px;
}

.product-swiper .swiper-button-next {
  background: url("/public/assets/icons/product_slide_right.png") no-repeat;
  width: 30px;
  height: 58px;
  top: 135px;
  right: -100px;
}

.product-swiper .swiper-button-prev {
  background: url("/public/assets/icons/product_slide_left.png") no-repeat;
  width: 30px;
  height: 58px;
  top: 135px;
  left: -100px;
}

.mobile-product-swiper .swiper-button-next {
  background: url("/public/assets/icons/mobile_slide_right.png") no-repeat;
  width: 36px;
  height: 36px;
}

.mobile-product-swiper .swiper-button-prev {
  background: url("/public/assets/icons/mobile_slide_left.png") no-repeat;
  width: 36px;
  height: 36px;
}

.mobile-home-news .swiper-button-next {
  top: unset;
  bottom: -73px;
  right: 50%;
  transform: translateX(70px);
}

.mobile-home-news .swiper-button-prev {
  top: unset;
  bottom: -73px;
  left: 50%;
  transform: translateX(-70px);
}

.mobile-product-swiper .swiper-button-next {
  top: unset;
  bottom: 50%;
  transform: translateY(50%);
  right: 0%;
}

.mobile-product-swiper .swiper-button-prev {
  top: unset;
  bottom: 50%;
  transform: translateY(50%);
  left: 0%;
}

.home-news .swiper-button-next::after {
  content: "Next";
  color: var(--Gray01, var(--CMS_Gray-8, #262c31));
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  position: relative;
  top: 45px;
}

.home-news .swiper-button-prev::after {
  content: "Prev";
  color: var(--Gray01, var(--CMS_Gray-8, #262c31));
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  position: relative;
  top: 45px;
}

.product-swiper .swiper-button-next::after {
  display: none;
}

.product-swiper .swiper-button-prev::after {
  display: none;
}
